<template>
  <div class="edit-coefficient-form">
    <!--     <a-row class="form-item-row" type="flex" align="middle">
      <a-col v-bind="calcTotalCol">
        <a-row type="flex" align="middle">
          <a-col :span="5">公式</a-col>
          <a-col class="gutter-row" flex="1">
            <template v-if="isEdit">
              <a-textarea v-model="formData.formula" />
            </template>
            <template v-else>
              <div style="white-space: pre-line;word-break: break-word;">{{ formData.formula }}</div>
            </template>
          </a-col>
        </a-row>
      </a-col>
    </a-row> -->

    <a-form-model :model="value">
      <div
        v-for="(formItem, formIndex) in formData.difficulties"
        :key="formItem.rowKey || formIndex"
        class="difficulty-type"
      >
        <a-form-model-item class="type-title">
          <a-row type="flex" align="middle" v-bind="flex">
            <template v-if="isOnlyCoefficient">
              <a-col v-bind="calcTotalCol" class="form-title-item type-title-preview">
                <div v-if="isEdit" style="width:35px;display:inline-block">
                  {{ `${numbers[formIndex] || formIndex + 1}、` }}
                </div>
                <a-input
                  v-if="isEdit"
                  style="font-weight: bold;font-size: 16px;"
                  :disabled="formItem.title == '急稿系数'"
                  v-model="formItem.title"
                  placeholder="请输入系数名称"
                ></a-input>
                <div v-else style="display:flex">
                  <div style="width:35px;">{{ `${numbers[formIndex] || formIndex + 1}、` }}</div>
                  <div>{{ `${formItem.title || ''}` }}</div>
                </div>
              </a-col>
              <a-col v-bind="col[2]">
                <a-row class="row-option" v-bind="flex">
                  <a-col>
                    <a-button v-if="isEdit" shape="circle" icon="minus" size="small" @click="delItem(formIndex)">
                    </a-button>
                  </a-col>
                  <a-col>
                    <a-button
                      v-if="isEdit"
                      :disabled="formData.difficulties.length > 9"
                      shape="circle"
                      icon="plus"
                      size="small"
                      @click="addItem(formIndex)"
                    ></a-button>
                  </a-col>
                </a-row>
              </a-col>
            </template>
            <template v-else>
              <a-col :span="20" class="form-title-item type-title-preview">
                <div v-if="isEdit" style="width:35px;display:inline-block">
                  {{ `${numbers[formIndex] || formIndex + 1}、` }}
                </div>
                <a-input
                  v-if="isEdit"
                  style="font-weight: bold;font-size: 16px;"
                  :disabled="formItem.title == '急稿系数'"
                  v-model="formItem.title"
                  placeholder="请输入系数名称"
                ></a-input>
                <div v-else style="display:flex">
                  <div style="width:35px;">{{ `${numbers[formIndex] || formIndex + 1}、` }}</div>
                  <div>{{ `${formItem.title || ''}` }}</div>
                </div>
              </a-col>
              <a-col v-bind="col[2]">
                <a-row class="row-option" v-bind="flex">
                  <a-col>
                    <a-button v-if="isEdit" shape="circle" icon="minus" size="small" @click="delItem(formIndex)">
                    </a-button>
                  </a-col>
                  <a-col>
                    <a-button
                      v-if="isEdit"
                      :disabled="formData.difficulties.length > 9"
                      shape="circle"
                      icon="plus"
                      size="small"
                      @click="addItem(formIndex)"
                    ></a-button>
                  </a-col>
                </a-row>
              </a-col>
            </template>
          </a-row>
        </a-form-model-item>
        <div
          v-for="(formChild, formChildIndex) in formItem.categories"
          :key="formChild.rowKey || formChildIndex"
          class="category-title"
        >
          <a-form-model-item>
            <a-row v-if="isEdit" type="flex" align="middle" v-bind="flex">
              <a-col v-bind="col[0]" class="category-title-preview">
                <div v-if="isEdit" style="width:35px;display:inline-block">
                  {{ `${formIndex + 1}.${formChildIndex + 1}` }}
                </div>
                <a-tree-select
                  style="width:calc(100% - 35px)"
                  @change="
                    value => {
                      treeSelectChange(value, formChild, formItem)
                    }
                  "
                  v-model="formChild.factorId"
                  v-if="isEdit"
                  :placeholder="formItem.title == '急稿系数' ? '急稿' : '请选择取值目标'"
                  ref="selectTree"
                  :disabled="formItem.title == '急稿系数'"
                >
                  <a-tree-select-node v-for="item in treeData" :value="item.value" :key="item.key" :title="item.title">
                    <template v-for="data in item.children">
                      <a-tree-select-node :value="data.value" :key="data.key" :title="data.title">
                        <a-tree-select-node v-for="i in data.children" :value="i.id" :key="i.id" :title="i.title">
                        </a-tree-select-node>
                      </a-tree-select-node>
                    </template>
                  </a-tree-select-node>
                </a-tree-select>
                <div v-else style="display:flex">
                  <div style="width:35px">{{ `${formIndex + 1}.${formChildIndex + 1}` }}</div>
                  <div>{{ getFactorTitle(formChild.factorId) }}</div>
                </div>
              </a-col>
              <a-col v-bind="col[1]">
                <a-select v-if="isEdit" v-model="formChild.type" :disabled="formItem.title == '急稿系数'">
                  <a-select-option
                    v-for="item in [
                      { label: '文本', value: 'INPUT' },
                      { label: '数值范围', value: 'RANGE' }
                    ]"
                    :key="item.value"
                    :value="item.value"
                    >{{ item.label }}
                  </a-select-option>
                </a-select>
                <template v-else>{{ formChild.type == 'INPUT' ? '文本' : '数值范围' }}</template>
              </a-col>
              <a-col v-bind="col[2]">
                <a-row class="row-option" v-bind="flex">
                  <a-col>
                    <a-button
                      v-if="isEdit"
                      shape="circle"
                      icon="minus"
                      size="small"
                      @click="delItem(formIndex, formChildIndex)"
                    ></a-button>
                  </a-col>
                  <a-col>
                    <a-button
                      v-if="isEdit"
                      shape="circle"
                      icon="plus"
                      size="small"
                      @click="addItem(formIndex, formChildIndex)"
                    ></a-button>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-form-model-item>
          <a-form-model-item v-for="(option, optionIndex) in formChild.options" :key="option.rowKey || optionIndex">
            <a-row type="flex" align="middle" v-bind="flex">
              <a-col v-bind="col[0]">
                <div
                  v-if="isEdit"
                  style="width:35px;display:inline-block"
                  v-text="optionIndex < 26 ? String.fromCharCode(65 + optionIndex) : optionIndex"
                ></div>
                <template v-if="isEdit">
                  <edit-range-select
                    v-if="formChild.type == 'RANGE'"
                    :formData="option.range"
                    :optionRangeType="option.range&&option.range.type"
                    :rangeKey="'type'"
                    :valueKey="'value'"
                    :unitKey="'unit'"
                    style="width:calc(100% - 35px);float: right;margin-top: 3px;"
                  />
                  <a-input
                    v-else
                    placeholder="选项"
                    style="width:calc(100% - 35px);display:inline-block"
                    :disabled="formItem.title == '急稿系数'"
                    v-model="option.title"
                  />
                </template>
                <template v-else>
                  <div v-if="formChild.type == 'INPUT'" style="display:flex">
                    <div
                      style="width:35px;"
                      v-text="optionIndex < 26 ? String.fromCharCode(65 + optionIndex) : optionIndex"
                    ></div>
                    <div>{{ `${option.title || ''}` }}</div>
                  </div>
                  <div v-else style="display:flex">
                    <div
                      style="width:35px;"
                      v-text="optionIndex < 26 ? String.fromCharCode(65 + optionIndex) : optionIndex"
                    ></div>
                    <div>{{ option.range ? getCalcRange(option.range) : option.range }}</div>
                  </div>
                </template>
              </a-col>
              <a-col v-bind="col[1]">
                <a-input-number v-if="isEdit" class="input-factor" placeholder="系数" v-model="option.factor" />
                <div v-else v-text="option.factor === false ? '' : option.factor" />
              </a-col>
              <a-col v-bind="col[2]">
                <a-row class="row-option" v-bind="flex" v-if="!formItem.isFast">
                  <a-col>
                    <a-button
                      v-if="isEdit"
                      shape="circle"
                      icon="minus"
                      size="small"
                      @click="delItem(formIndex, formChildIndex, optionIndex)"
                    ></a-button>
                  </a-col>
                  <a-col>
                    <a-button
                      v-if="isEdit"
                      :disabled="formChild.options.length > 25"
                      shape="circle"
                      icon="plus"
                      size="small"
                      @click="addItem(formIndex, formChildIndex, optionIndex)"
                    >
                    </a-button>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-form-model-item>
        </div>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import EditRangeSelect from '@/components/edit/edit-range-select.vue'
import { getAction } from '@/api/manage'
export default {
  components: {
    EditRangeSelect
  },
  data() {
    return {
      // 是否单拎系数（后续应该要放入每一项的数据中做判断
      isOnlyCoefficient: false,
      numbers: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'],
      flex: {
        type: 'flex',
        justify: 'space-between'
      },
      treeData: [
        {
          title: '管理系统',
          value: '0-0',
          key: '0-0',
          id: '0-0',
          showList: [],
          children: [
            {
              title: '作品详情',
              value: 'PRODUCT_DETAIL',
              key: 'PRODUCT_DETAIL',
              id: 'PRODUCT_DETAIL',
              showList: [],
              children: [],
              isShow: true
            },
            {
              title: '系数参数',
              value: 'TASK_DETAIL',
              key: 'TASK_DETAIL',
              id: 'TASK_DETAIL',
              showList: [],
              children: [],
              isShow: true
            },
            {
              title: '急稿',
              value: 'FAST_DETAIL',
              key: 'FAST_DETAIL',
              id: 'FAST_DETAIL',
              showList: [],
              children: [],
              isShow: true
            }
          ]
        },
        {
          title: '翻译系统',
          value: '0-1',
          key: '0-1',
          id: '0-1',
          showList: [],
          children: [
            {
              title: '文本框',
              value: 'TEXT_AREA',
              key: 'TEXT_AREA',
              id: 'TEXT_AREA',
              isShow: true
            },
            {
              title: '术语表',
              value: 'TERM',
              key: 'TERM',
              id: 'TERM',
              isShow: true
            }
          ]
        }
      ],
      replaceFields: {
        children: 'children',
        value: 'value',
        key: 'key',
        title: 'title'
      },
      factors: []
    }
  },
  computed: {
    calcTotalCol() {
      return this.col.slice(0, 2).reduce(
        (total, item) => ({
          ...item,
          span: item.span + total.span
        }),
        { span: 1 }
      )
    },
    col() {
      // return this.isEdit
      //     ? [{ span: 14 }, { span: 5 }, { span: 3 }]
      //     : [{ span: 20 }, { span: 4 }, { span: 0 }];
      return [{ span: 14 }, { span: 5 }, { span: 3 }]
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    isEdit: {
      type: Boolean,
      default: () => false
    },
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    formData() {
      if (!this.formData.difficulties.length) {
        this.formData = Object.assign(this.formData, { difficulties: [this.genType()] })
      }
    }
  },
  created() {
    this.getProductDetail()
    if (this.formData.difficulties == undefined || !this.formData.difficulties.length) {
      this.formData = Object.assign(this.formData, { difficulties: [this.genType()] })
      this.$emit('setDif', this.formData.difficulties)
    }
  },
  mounted() {},
  methods: {
    treeSelectChange(value, data, formItem) {
      data.factorId = value
      if (data.factorId == 'FAST_DETAIL') {
        formItem.isFast = true
        formItem.title = '急稿系数'
        data.options[0].title = '急稿'
        data.options[1].title = '普通'
        data.factorId = null
        data.type = 'INPUT'
      }
    },
    async getProductDetail() {
      const res = await getAction('/productDetail/page', {
        current: 1,
        size: -1,
        domain: 'PRODUCT_DETAIL',
        type: 'GLOBAL'
      })
      if (res.code == 200) {
        this.treeData[0].children[0].children = [...res.data.records]
      }
      const data = await getAction('/productDetail/page', {
        current: 1,
        size: -1,
        domain: 'TASK_DETAIL',
        type: 'GLOBAL'
      })
      if (data.code == 200) {
        this.treeData[0].children[1].children = [...data.data.records]
      }
    },
    getFactorTitle(id) {
      let text = ''
      this.treeData.forEach(item => {
        if (item.id == id) {
          text = item.title
        }
        item.children.forEach(i => {
          if (i.id == id) {
            text = i.title
          }
          if (i.children) {
            i.children.forEach(data => {
              if (data.id == id) {
                text = data.title
              }
            })
          }
        })
      })
      return text
    },
    getCalcRange(data) {
      let text = ''
      let unit = ''
      if (data.unit) {
        switch (data.unit) {
          case 'EACH':
            unit = '个'
            break
          case 'CHAPTER':
            unit = '话'
            break
          case 'VOLUME':
            unit = '部'
            break
          case 'PAGE':
            unit = '页'
            break
          case 'WORD':
            unit = '字'
            break
          case 'THOUSAND':
            unit = '千字'
            break
          case 'GRID':
            unit = '格'
            break
          case 'PERCENT':
            unit = '%'
            break
        }
        if (data.type) {
          switch (data.type) {
            case 'GT':
              data.value != null && (text = '>' + data.value + unit)
              break
            case 'LT':
              data.value != null && (text = '<' + data.value + unit)
              break
            case 'EQ':
              data.value != null && (text = '=' + data.value + unit)
              break
            case 'BETWEEN':
              data.start != null && (text = data.start + '~' + data.end + unit)
              break
          }
          return text
        }
        return text
      }
      return text
    },
    factorIdChange(value, child) {
      child.factorId = value
    },
    factorChange(value, formItem) {
      this.treeData.forEach(item => {
        if (item.bigType == value) {
          formItem.factors = item.children
        }
      })
      for (let item of formItem.categories) {
        item.factorId = undefined
      }
    },
    async getFactorTree() {
      const res = await getAction('factor/page', {
        own: 'DIFFICULT',
        current: 1,
        size: -1
      })
      if (res.code == 200) {
        res.data.records.forEach(item => {
          if (this.treeData?.map(i => i.bigType).indexOf(item.bigType) == -1) {
            this.treeData.push({ bigType: item.bigType, children: [] })
          }
        })
        res.data.records.forEach(item => {
          this.treeData.forEach(i => {
            if (i.bigType == item.bigType) {
              i.children.push(item)
            }
          })
        })
        this.formData.factors.forEach(item => {
          this.treeData.forEach(i => {
            if (item.title == i.bigType) {
              item = Object.assign(item, { factors: i.children })
            }
          })
        })
        this.$emit('setDif', this.formData.difficulties)
      }
    },
    genType(categoryCount = 1, optionCount) {
      return {
        rowKey: Math.random(),
        title: undefined,
        weight: undefined,
        categories: Array.from(Array(categoryCount), () => this.genCategory(optionCount))
      }
    },
    genCategory(optionCount = 2) {
      return {
        rowKey: Math.random(),
        title: undefined,
        options: Array.from(Array(optionCount), () => this.genOption()),
        factorId: undefined,
        type: undefined
      }
    },
    genOption() {
      return {
        rowKey: Math.random(),
        title: undefined,
        factor: undefined,
        range: {
          type: 'GT',
          unit: 'CHAPTER',
          value: null,
          start: null,
          end: null,
          id: null
        }
      }
    },
    addItem(typeIndex, categoryIndex, optionIndex) {
      if (categoryIndex == undefined) {
        this.formData.difficulties.push(this.genType())
        this.$emit('setDif', this.formData.difficulties)
      } else if (optionIndex == undefined) {
        this.formData.difficulties[typeIndex].categories.splice(categoryIndex + 1, 0, this.genCategory())
        this.$emit('setDif', this.formData.difficulties)
      } else {
        this.formData.difficulties[typeIndex].categories[categoryIndex].options.splice(
          optionIndex + 1,
          0,
          this.genOption()
        )
        this.$emit('setDif', this.formData.difficulties)
      }
    },
    delItem(typeIndex, categoryIndex, optionIndex) {
      if (categoryIndex == undefined) {
        if (this.formData.difficulties.length == 1) {
          this.formData.difficulties.splice(
            typeIndex,
            1,
            this.genType(
              this.formData.difficulties[typeIndex]?.categories.length,
              this.formData.difficulties[typeIndex]?.categories[0]?.options.length
            )
          )
          this.$emit('setDif', this.formData.difficulties)
        } else {
          this.formData.difficulties.splice(typeIndex, 1)
          this.$emit('setDif', this.formData.difficulties)
        }
      } else if (optionIndex == undefined) {
        if (this.formData.difficulties[typeIndex].categories.length == 1) {
          this.formData.difficulties[typeIndex].categories.splice(
            categoryIndex,
            1,
            this.genCategory(this.formData.difficulties[typeIndex]?.categories[categoryIndex]?.options.length)
          )
          this.$emit('setDif', this.formData.difficulties)
        } else {
          this.formData.difficulties[typeIndex].categories.splice(categoryIndex, 1)
          this.$emit('setDif', this.formData.difficulties)
        }
      } else {
        if (this.formData.difficulties[typeIndex].categories[categoryIndex].options.length == 1) {
          this.formData.difficulties[typeIndex].categories[categoryIndex].options.splice(
            optionIndex,
            1,
            this.genOption()
          )
          this.$emit('setDif', this.formData.difficulties)
        } else {
          this.formData.difficulties[typeIndex].categories[categoryIndex].options.splice(optionIndex, 1)
          this.$emit('setDif', this.formData.difficulties)
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.ant-form-item {
  margin-bottom: 0;
}

.form-title-item {
  display: flex;
  align-items: center;
  padding-top: 1px;
}

/deep/ .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 40px !important;
}

.edit-coefficient-form {
  // padding-right: 8px;
  .difficulty-type:not(:first-of-type) {
    // border-top: 1px solid #eaecee;
  }

  .difficulty-type {
    padding-top: 10px;

    .type-title {
      // margin-bottom: 10px;
      .type-title-preview {
        font-weight: bold;
        font-size: 16px;

        /deep/ .ant-input-affix-wrapper {
          font-size: 16px;
          font-weight: bold;

          .ant-input {
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }

    .type-title:not(:first-of-type) {
      margin: 10px 0;
      // border-top: 1px solrowKey #eaecee;
    }

    .category-title {
      // border-top: 1px solrowKey #eaecee;
      // padding: 10px 0;
      .category-title-preview {
        font-weight: bold;

        /deep/ .ant-input-affix-wrapper {
          .ant-input {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.form-item-row {
  margin: 15px 0 20px 0;
}

.ant-row-flex {
  .row-option {
    display: none;

    .ant-col {
      &:last-child {
        .ant-btn {
          margin-left: 10px;
        }
      }
    }
  }

  &:hover {
    .row-option {
      display: flex;
    }
  }
}
</style>
